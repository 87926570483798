import { useState } from "react";
import Config from "../config.js";
import "../styles/ReportGenerator.css";

const ReportGenerator = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [images, setImages] = useState([]);
  const [templateFile, setTemplateFile] = useState(null);
  const [status, setStatus] = useState("");

  const handleAudioChange = (e) => setAudioFile(e.target.files[0]);
  const handleImagesChange = (e) => setImages([...e.target.files]);
  const handleTemplateChange = (e) => setTemplateFile(e.target.files[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || !audioFile) {
      setStatus("Please provide your name, email, and upload an audio file.");
      return;
    }

    setStatus("Submitting your request...");

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("audio", audioFile);
    if (images.length > 0) {
      images.forEach((image, index) => formData.append(`image_${index}`, image));
    }
    if (templateFile) {
      formData.append("template", templateFile);
    }

    try {
      const response = await fetch(`${Config.API_BASE_URL}/generate-report`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setStatus(
          "Request submitted! Safex AI is processing your report. Check " +
            email +
            " in 10 mins to 2 hours."
        );
        setName("");
        setEmail("");
        setAudioFile(null);
        setImages([]);
        setTemplateFile(null);
      } else {
        const errorData = await response.json();
        setStatus(`Error: ${errorData.error || "Unknown error"}`);
      }
    } catch (error) {
      setStatus("Error occurred. Try again later.");
    }
  };

  return (
    <div className="report-generator-container">
      <div className="logo-section">
        <img src="/logo.png" alt="Auraplan Logo" className="logo-image" />
        <span className="logo-text">Auraplan</span>
      </div>
      <main className="report-generator-main">
        <div className="content-wrapper">
          <div className="title-section">
            <h1>Safex AI</h1>
            <h2>Automated AI Report Generator</h2>
          </div>
          <div className="form-and-description">
            <form onSubmit={handleSubmit} className="report-form">
              <div className="input-group">
                <label>Name *</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="input-group">
                <label>Email *</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                  required
                />
              </div>
              <div className="input-group">
                <label>Audio File *</label>
                <input
                  type="file"
                  accept="audio/*" // Accepts all audio formats (e.g., .mp3, .wav)
                  onChange={handleAudioChange}
                  required
                />
              </div>
              <div className="input-group">
                <label>Images (Optional)</label>
                <input type="file" accept="image/*" multiple onChange={handleImagesChange} />
              </div>
              <div className="input-group">
                <label>Template (Optional)</label>
                <input type="file" accept=".doc,.docx,.pdf" onChange={handleTemplateChange} />
              </div>
              <button type="submit" className="submit-btn">Submit Request</button>
              {status && <p className={`status-message ${status.includes("submitted") ? "success" : "error"}`}>{status}</p>}
            </form>
            <div className="description">
              <p>Safex AI transforms your audio into advanced reports:</p>
              <ul>
                <li>Input name, email, and audio (required).</li>
                <li>Add images or templates (optional).</li>
                <li>Submit to start AI processing.</li>
                <li>Get your report in 10 mins to 2 hours via email.</li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ReportGenerator;