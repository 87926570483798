import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Popup from "../components/Popup";
import Spinner from "./Spinner.js";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "../styles/PDFGeneratorModal.css";
import Config from "../config.js";

const PDFGeneratorModal = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    file: null,
    certificateType: "Flanschmonteur",
    version: "Digital", // "Digital" or "Druckbar"
    organization: "Auraplan Academy", // default for Digital version
    includeBarcode: false,
  });

  const [popupMessage, setPopupMessage] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      setPopupMessage("Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.");
      setPopupVisible(true);
      setLoading(false);
      return;
    }
    if (!formData.file) {
      setPopupMessage("Bitte wählen Sie eine Datei zum Hochladen aus.");
      setPopupVisible(true);
      setLoading(false);
      return;
    }
    // Der Wert printableVersion wird aus dem Versions-Toggle abgeleitet.
    const printableVersion = formData.version === "Druckbar";

    const data = new FormData();
    data.append("file", formData.file);
    data.append("certificateType", formData.certificateType);
    data.append("printableVersion", printableVersion);
    // Organisation wird nur bei Digital ausgewählt
    if (!printableVersion) {
      data.append("organization", formData.organization);
    }
    data.append("includeBarcode", formData.includeBarcode);

    try {
      const response = await axios.post(`${Config.API_BASE_URL}/generate`, data, {
        headers: {
          "x-access-token": token,
        },
        responseType: "blob",
      });

      // Aktuelles Datum im Format DDMMYY
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = String(currentDate.getFullYear()).slice(-2);
      const formattedDate = `${day}${month}${year}`;

      const fileName =
        formData.certificateType === "Namensschild"
          ? `namensschild_${formattedDate}.pdf`
          : `Auraplan_${formData.certificateType}_${formattedDate}.zip`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setPopupMessage("Zertifikate wurden erfolgreich generiert!");
      setPopupVisible(true);
    } catch (error) {
      console.error("Fehler beim Generieren der Zertifikate:", error);
      if (error.response?.status === 400) {
        setPopupMessage(error.response.data.error || "Es gibt ein Problem mit der hochgeladenen Datei. Bitte überprüfen Sie die Daten oder wenden Sie sich an den Systemadministrator.");
      } else if (error.response?.status === 401) {
        setPopupMessage("Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.");
      } else {
        setPopupMessage("Fehler beim Generieren der Zertifikate. Bitte versuchen Sie es später erneut.");
      }
      setPopupVisible(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
    if (popupMessage.includes("erneut an")) {
      navigate("/"); // Weiterleitung zur Anmeldung
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Zertifikatsgenerator</h2>
        {loading ? (
          <Spinner />
        ) : (
          <form onSubmit={handleGenerate}>
            {/* Dateiupload */}
            <div className="form-group">
              <label htmlFor="fileInput">Datei für Zertifikatsdaten hochladen</label>
              <input
                type="file"
                name="file"
                id="fileInput"
                accept=".xls,.xlsx,.csv"
                required
                onChange={handleFileChange}
              />
            </div>

            {/* Zertifikatstyp Dropdown */}
            <div className="form-group">
              <label htmlFor="certificateType">Zertifikatstyp</label>
              <select
                id="certificateType"
                name="certificateType"
                value={formData.certificateType}
                onChange={(e) =>
                  setFormData({ ...formData, certificateType: e.target.value })
                }
              >
                <option value="Flanschmonteur">Flanschmonteur</option>
                <option value="PSAgA">PSAgA</option>
                <option value="Hubarbeitsbühnen">Hubarbeitsbühnen</option>
                <option value="Anschlagen von Lasten">Anschlagen von Lasten</option>
                <option value="SiGeKo">SiGeKo</option>
                <option value="SCC Document 16">SCC Document 16</option>
                <option value="EHS Manager">EHS Manager</option>
                <option value="Sifa LEK 1-3">Sifa LEK 1-3</option>
                <option value="Sifa LEK 1-5">Sifa LEK 1-5</option>
                <option value="Namensschild">Namensschild</option>
              </select>
            </div>

            {/* Versionsauswahl (Digital vs. Druckbar) */}
            <div className="form-group">
              <label className="toggle-label">Version</label>
              <ToggleButtonGroup
                className="custom-toggle-group"
                value={formData.version}
                exclusive
                onChange={(event, newVersion) => {
                  if (newVersion !== null) {
                    setFormData({ ...formData, version: newVersion });
                  }
                }}
                aria-label="Version"
              >
                <ToggleButton value="Digital" aria-label="Digital">
                  Digital
                </ToggleButton>
                <ToggleButton value="Druckbar" aria-label="Druckbar">
                  Druckbar
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            {/* Organisationsauswahl – nur anzeigen, wenn Version "Digital" */}
            {formData.version === "Digital" && (
              <div className="form-group">
                <label className="toggle-label">Organisation</label>
                <ToggleButtonGroup
                  className="custom-toggle-group"
                  value={formData.organization}
                  exclusive
                  onChange={(event, newOrganization) => {
                    if (newOrganization !== null) {
                      setFormData({ ...formData, organization: newOrganization });
                    }
                  }}
                  aria-label="Organisation"
                >
                  <ToggleButton value="Auraplan Engineering" aria-label="Auraplan Engineering">
                    Auraplan Engineering
                  </ToggleButton>
                  <ToggleButton value="Auraplan Academy" aria-label="Auraplan Academy">
                    Auraplan Academy
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            )}
            
            {/* (Optional) Barcode-Toggle, derzeit auskommentiert */}
            
            {/* <div className="form-group">
              <label htmlFor="includeBarcode" className="toggle-label">
              Zertifikat auf Blockchain veröffentlichen & Barcode hinzufügen
              </label>
              <div className="toggle-container">
                <input
                  type="checkbox"
                  id="includeBarcode"
                  checked={formData.includeBarcode}
                  onChange={(e) =>
                    setFormData({ ...formData, includeBarcode: e.target.checked })
                  }
                />qa
                <span className="slider"></span>
              </div>
            </div> */}
           

            {/* Buttons */}
            <div className="form-buttons">
              <button type="submit" className="btn-primary">
                Zertifikate generieren
              </button>
              <button type="button" className="btn-secondary" onClick={closeModal}>
                Schließen
              </button>
            </div>
          </form>
        )}
        {popupVisible && <Popup message={popupMessage} onClose={handlePopupClose} />}
      </div>
    </div>
  );
};

export default PDFGeneratorModal;
