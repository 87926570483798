import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QrScanner from "react-qr-scanner";
import "../styles/VerifyCertificate.css";
import Config from "../config.js";

const VerifyCertificate = () => {
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState(null);
  const [error, setError] = useState("");
  const [showScanner, setShowScanner] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    verifyCertificate();
  }, []);

  const getCameraConstraints = () => {
    // Check if the device is a mobile phone
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    return isMobile ? { facingMode: { exact: "environment" } } : { facingMode: "user" };
  };
  const verifyCertificate = async (qrData = null) => {
    let encodedData = qrData || new URLSearchParams(window.location.search).get("data");

    if (!encodedData) {
      setCertificate(null);
      setError("❌ Keine Zertifikatsdaten gefunden.");
      return;
    }

    try {
      setLoading(true);
      setError(""); // ✅ Clear error before making new request

      // ✅ Remove Base URL if present
      if (encodedData.startsWith("https://portal.auraplan.com/verify?data=")) {
        encodedData = encodedData.split("data=")[1]; // Extract only the encoded part
      }

      console.log("📌 Encoded Data:", encodedData);

      // ✅ Ensure Base64 string is valid
      encodedData = encodedData.replace(/[^A-Za-z0-9+/=]/g, "");
      while (encodedData.length % 4 !== 0) {
        encodedData += "=";
      }

      let decodedData;
      try {
        decodedData = atob(decodeURIComponent(encodedData)); // Decode Base64 safely
      } catch (error) {
        console.error("❌ Base64 Decoding Error:", error);
        setCertificate(null);
        setError("⚠️ Ungültiger QR-Code. Bitte erneut scannen.");
        return;
      }

      console.log("📌 Decoded Data:", decodedData);
      const [issuedTo, dob, certificateHash] = decodedData.split("|");

      console.log("📌 Sending to Backend:", { issuedTo, dob, certificateHash });

      const response = await fetch(`${Config.API_BASE_URL}/verify-certificate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ certificate_hash: certificateHash }),
      });

      const data = await response.json();
      console.log("📌 Backend Response:", data);

      if (!data.verified) {
        setCertificate(null);
        setError("❌ Dieses Zertifikat existiert nicht oder ist ungültig.");
        return;
      }

      setCertificate({
        name: issuedTo,
        dob,
        type: data.data.certificateType,
        issueDate: data.data.issueDate,
        expirationDate: data.data.expirationDate,
      });
    } catch (err) {
      console.error("❌ Backend API Error:", err);
      setCertificate(null);
      setError("⚠️ Verbindungsfehler. Bitte versuchen Sie es später erneut.");
    } finally {
      setLoading(false);
      setShowScanner(false);
    }
  };

  return (
    <div className="verify-container">
      {/* ✅ Updated Header with Logo */}
      <header className="certificate-header">
        <img src="/auraplan_academy_icon.png" alt="Auraplan Logo" className="logo" />
        <h1>Überprüfung digitaler Zertifikate – Powered by Blockchain</h1>
      </header>

      <main className="verify-main">
        <p>Überprüfen Sie digitale Zertifikate sicher und transparent.</p>

        {showScanner ? (
          <div className="qr-scanner">
            <QrScanner
              delay={300}
              onScan={(data) => data && verifyCertificate(data.text)}
              onError={(err) => console.error(err)}
              style={{ width: "100%" }}
              constraints={{
                video: getCameraConstraints(), // Dynamically set the correct mode
              }}
            />
            <button className="close-scanner-btn" onClick={() => setShowScanner(false)}>✖ Schließen</button>
          </div>
        ) : (
          <button className="scan-btn" onClick={() => setShowScanner(true)}>📷 QR-Code scannen</button>
        )}

        {loading && <p className="loading">⏳ Überprüfung läuft...</p>}
        {error && <p className="error">{error}</p>}

        {certificate ? (
          <div className="certificate-card">
            <h3>
                <img src="/check.png" alt="Verifiziert" className="verified-icon" />
                Zertifikat Verifiziert
            </h3>
            <p><strong>Name:</strong> {certificate.name}</p>
            <p><strong>Geburtsdatum:</strong> {certificate.dob}</p>
            <p><strong>Typ:</strong> {certificate.type}</p>
            <p><strong>Ausgestellt:</strong> {certificate.issueDate}</p>
            <p><strong>Gültig bis:</strong> {certificate.expirationDate}</p>
            <div className="blockchain-note">
              🔗 Dieses digitale Zertifikat wurde mit Blockchain-Technologie gespeichert und ist manipulationssicher.
            </div>
          </div>
        ) : (
          !loading && <p className="info">Bitte scannen Sie ein Zertifikat.</p>
        )}
      </main>
    </div>
  );
};

export default VerifyCertificate;
