import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import PDFGenerator from "./pages/PDFGenerator";
import ReportGenerator from "./pages/ReportGenerator";
import NotFound from "./pages/NotFound";
import VerifyCertificate from "./pages/VerifyCertificate";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  return token ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/pdf-generator"
          element={
            <PrivateRoute>
              <PDFGenerator />
            </PrivateRoute>
          }
        />
        <Route path="/report-generator" element={<ReportGenerator />} /> {/* No PrivateRoute */}
        <Route path="/verify" element={<VerifyCertificate />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;